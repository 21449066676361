import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot, doc, updateDoc, addDoc, getDoc, connectFirestoreEmulator } from 'firebase/firestore';
import firebaseConfig from './firebaseConfig.js';
import goldMedal from './assets/medals/medal (1).png';
import silverMedal from './assets/medals/medal (2).png';
import bronzeMedal from './assets/medals/medal (3).png';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const peopleList = document.getElementById('people-list');
const addUserForm = document.getElementById('add-user-form');

// Define global arrays to store all users and filtered users
let allUsers = [];
let filteredUsers = [];
let previousTopUserId = null;

// Use Firestore emulator in development environment
if (window.location.hostname === 'localhost') {
    connectFirestoreEmulator(db, 'localhost', 8080);
}

function renderRankingScoreboard() {
    const rankingList = document.getElementById('ranking-list');
    rankingList.innerHTML = '';

    const topThree = [...allUsers].sort((a, b) => b.drinks - a.drinks).slice(0, 3);

    topThree.forEach((user, index) => {
        const listItem = document.createElement('li');

        let medalSrc = '';
        if (index === 0) medalSrc = goldMedal; // Gold
        else if (index === 1) medalSrc = silverMedal; // Silver
        else if (index === 2) medalSrc = bronzeMedal; // Bronze

        listItem.innerHTML = `
            <span>${user.name}</span>
            <span>
                ${user.drinks} drinks 
                <img src="${medalSrc}" class="medal" alt="medal">
            </span>
        `;
        rankingList.appendChild(listItem);
    });
}

// Function to render filtered users
function renderFilteredUsers() {
    peopleList.innerHTML = '';

    // Determine the top three users based on all users, not filtered users
    const topThree = [...allUsers].sort((a, b) => b.drinks - a.drinks).slice(0, 3);

    filteredUsers.forEach((person) => {
        const personRow = document.createElement('tr');

        // Determine the medal based on position in topThree
        let medalSrc = '';
        if (topThree[0] && topThree[0].id === person.id) medalSrc = goldMedal; // Gold
        else if (topThree[1] && topThree[1].id === person.id) medalSrc = silverMedal; // Silver
        else if (topThree[2] && topThree[2].id === person.id) medalSrc = bronzeMedal; // Bronze

        personRow.innerHTML = `
            <td>
                ${person.name} ${medalSrc ? `<img src="${medalSrc}" class="medal" alt="medal">` : ''}
            </td>
            <td>${person.drinks}</td>
            <td>€ ${(person.drinks * 0.8).toFixed(2)}</td>
            <td class="actions">
                <button class="decrement-btn" data-id="${person.id}">-</button>
                <button class="increment-btn" data-id="${person.id}">+</button>
            </td>
        `;
        peopleList.appendChild(personRow);

        renderRankingScoreboard();
    });

    // Check if the top user has changed in the overall list
    const currentTopUserId = topThree[0] ? topThree[0].id : null;

    if (previousTopUserId !== currentTopUserId) {
        if (currentTopUserId) {
        }
        previousTopUserId = currentTopUserId;
    }
}

// Function to update filtered users based on search input
function updateFilteredUsers(searchInput) {
    filteredUsers = allUsers
        .filter((person) => person.name.toLowerCase().includes(searchInput.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically by name

    renderFilteredUsers();
}

// Function to handle search input
function handleSearchInput() {
    const searchInput = document.getElementById('search-input').value;
    updateFilteredUsers(searchInput);
}

// Event listener for search input
document.getElementById('search-input').addEventListener('input', handleSearchInput);

// Function to increment drink count
async function incrementDrink(id) {
    const userDocRef = doc(db, 'people', id);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const updatedDrinks = userData.drinks + 1;
        await updateDoc(userDocRef, {
            drinks: updatedDrinks,
            lastUpdated: new Date()
        });
    }
}

// Function to decrement drink count
async function decrementDrink(id) {
    const userDocRef = doc(db, 'people', id);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const updatedDrinks = Math.max(userData.drinks - 1, 0);
        await updateDoc(userDocRef, { drinks: updatedDrinks });
    }
}

// Function to add a new user
addUserForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const nameInput = document.getElementById('add-input');
    const name = nameInput.value.trim();

    if (name) {
        const newUserRef = await addDoc(collection(db, 'people'), {
            name: name,
            drinks: 0
        });
        const newUser = { id: newUserRef.id, name: name, drinks: 0 };

        // Check if the user already exists in the allUsers array
        const userExists = allUsers.some(user => user.id === newUser.id);
        if (!userExists) {
            allUsers.push(newUser);
            updateFilteredUsers(document.getElementById('search-input').value);
        }
        nameInput.value = '';
    }
});

// Initial rendering
onSnapshot(collection(db, 'people'), (snapshot) => {
    allUsers = [];
    snapshot.forEach((doc) => {
        const userData = doc.data();
        allUsers.push({ id: doc.id, ...userData });
    });
    updateFilteredUsers(document.getElementById('search-input').value);
});

// Event listener for increment and decrement buttons using event delegation
document.getElementById('people-list').addEventListener('click', (event) => {
    const target = event.target;
    if (target.classList.contains('increment-btn')) {
        const id = target.getAttribute('data-id');
        incrementDrink(id);
    } else if (target.classList.contains('decrement-btn')) {
        const id = target.getAttribute('data-id');
        decrementDrink(id);
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const togglePriceListBtn = document.getElementById('toggle-price-list');
    const priceList = document.querySelector('.price-list');
    const rankingScoreboard = document.querySelector('.ranking-scoreboard');

    togglePriceListBtn.addEventListener('click', () => {
        if (priceList.style.display === 'none' || priceList.style.display === '') {
            priceList.style.display = 'block';
            rankingScoreboard.style.display = 'block';
            togglePriceListBtn.textContent = 'Verberg prijzen & ranking';
        } else {
            priceList.style.display = 'none';
            rankingScoreboard.style.display = 'none';
            togglePriceListBtn.textContent = 'Toon prijzen & ranking';
        }
    });
});

renderRankingScoreboard();

// Initial render
renderFilteredUsers();

// Get the modal element
const modal = document.getElementById('modal');

// Get the button that opens the modal
const openModalBtn = document.getElementById('add-user-btn');

// Get the <span> element that closes the modal
const closeBtn = document.getElementsByClassName('close')[0];

// When the user clicks the button, open the modal
openModalBtn.onclick = function() {
    modal.style.display = 'block';
}

// When the user clicks on <span> (x), close the modal
closeBtn.onclick = function() {
    modal.style.display = 'none';
}

// When the user clicks anywhere outside the modal, close it
window.onclick = function(event) {
    if (event.target === modal) {
        modal.style.display = 'none';
    }
}
